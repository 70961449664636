<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" :src="logo" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Slider Area  -->
    <div class="slider-wrapper poss_relative">
      <div
        class="
          justify-center
          slide slide-style-2
          slider-video-bg
          d-flex
          align-center
        "
        data-black-overlay="6"
      >
        <v-container>
          <v-row align="center">
            <v-col cols="12">
              <div class="text-center inner pt_sm--40">
                <span class="theme-color font-700">{{
                  $store.state.iglesia.Nombre
                }}</span>
                <h1 class="heading-title mt--20">
                  {{ $store.state.iglesia.Donacion_Titulo }}
                </h1>
                <!-- <p class="description">
                                    {{ $store.state.iglesia.SubLema }}
                                </p> -->
                <a
                  class="btn-default btn-large mt--30"
                  v-if="
                    IsFull($store.state.iglesia.PaypalKey) ||
                    IsFull($store.state.iglesia.ATHMovilKey)
                  "
                  @click="
                    $store.dispatch('get_Motivos_Donacion').then((res) => {
                      dialog = true;
                    })
                  "
                  ><span>{{ $store.state.iglesia.Donacion_SubTitulo }}</span></a
                >

                <a
                  class="btn-default btn-large mt--30 ml-2"
                  v-if="$store.state.admin.Admin == 1"
                  @click="
                    $store.dispatch('get_Donaciones').then((res) => {
                      donaciones = res;
                      verDonaciones = true;
                    })
                  "
                  ><span>Ver Donaciones</span></a
                >
                <motivos />
              </div>
              <div
                class="flex justify-center"
                v-if="$store.state.admin.Admin == 1"
              >
                <a
                  class="btn-default btn-large mt--30"
                  @click="credencialesPop = true"
                  ><span>Agregar Credenciales</span></a
                >
              </div>
            </v-col>
          </v-row>
        </v-container>

        <img
          :src="$store.state.iglesia.Donacion_Foto"
          alt=""
          class="object-fit"
        />
      </div>
    </div>

    <!-- Start Portfolio Area -->
    <div class="portfolio-area pt-6 bg_color--1">
      <div class="portfolio-wrapper">
        <v-container>
          <v-row>
            <v-col lg="12">
              <div class="text-center section-title">
                <h2 class="heading-title">
                  {{ $store.state.iglesia.Lema }}
                </h2>
                <p class="description">
                  {{ $store.state.iglesia.SubLema }}
                </p>
              </div>
            </v-col>
          </v-row>
          <div class="mt-10">
            <PortfolioThree />
          </div>
        </v-container>
      </div>
    </div>

    <FooterTwo />

    <v-dialog v-model="dialog" width="700">
      <v-card>
        <!-- <v-card-title class="text-h5 grey lighten-2">
          Privacy Policy
        </v-card-title> -->

        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-stepper-step :complete="e1 > 1" step="1">
              Cantidad para donar
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="e1 > 2" step="2">
              Seleccionar metodo
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3"> Finalizado </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-select
                  :items="$store.state.motivos_donacion"
                  item-text="Motivo_Donacion"
                  item-value="Id"
                  return-object
                  v-model="toSend.Motivo"
                  label="Motivo"
                  class="mt-2"
                  outlined
                  clearable
                ></v-select>

                <div class="flex">
                  <v-text-field
                    v-for="(item, index) in form"
                    :key="index"
                    v-model="toSend[item.model]"
                    :type="item.type"
                    :label="item.label"
                    outlined
                    :class="item.class"
                    :rules="item.rules"
                  />

                  <!-- <v-text-field
                    v-model="Email"
                    type="text"
                    label="Email (Opcional)"
                    outlined
                    class="text-center text-lg-body-1 mt-3"
                  />
                  <v-text-field
                    v-model="Telefono"
                    type="tel"
                    label="Telefono (Opcional)"
                    outlined
                    class="text-center text-lg-body-1 mt-3"
                  /> -->
                </div>
                <v-text-field
                  v-model="toSend.Telefono"
                  type="tel"
                  label="Telefono (Opcional)"
                  outlined
                  class="text-center text-lg-body-1 mt-3"
                />
                <v-text-field
                  v-model="toSend.money"
                  type="number"
                  label="Cantidad a donar"
                  outlined
                  class="text-center text-lg-body-1 mt-3"
                  prefix="$"

                  :rules="[v => !!v || 'Agregue cantidad', v => v > 0 || 'Ingrese cantidad mayor a 0']"
                />

                <v-text-field
                  v-model="toSend.comentario"
                  type="text"
                  label="Comentario (Opcional)"
                  outlined
                  class="text-center text-lg-body-1 mt-3"
                />

                <v-btn color="primary" @click="validate()"> Continuar </v-btn>

                <v-btn text @click="dialog = false"> Cancelar </v-btn>
              </v-form>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-card-text>
                <v-row class="items-center">
                  <v-col>
                    <img
                      :src="$store.state.iglesia.Donacion_Foto"
                      alt=""
                      class=""
                    />
                  </v-col>

                  <v-col>
                    <div ref="paypal"></div>
                    <div class="ath" id="ATHMovil_Checkout_Button"></div>
                  </v-col>
                </v-row>
                <!-- <v-btn
                  color="success"
                  class="mt-3"
                  @click="Test('123', 'ATH Movil')"
                  block
                  >ATH Movil</v-btn
                > -->
              </v-card-text>

              <!-- <v-btn color="primary" @click="e1 = 3"> Continue </v-btn>

               -->
              <v-btn text @click="e1 = 1"> Atras </v-btn>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-card class="mb-12" color="grey lighten-2" height="200px" flat>
                <v-icon size="60" color="success" style="margin: 35px 300px"
                  >mdi-check</v-icon
                >
                <div class="flex justify-center text-body-2 mt-n5">
                  Donacion completada exitosamente
                </div>
              </v-card>

              <v-btn color="primary" @click="dialog = false"> Cerrar </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>

    <v-dialog v-model="credencialesPop" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Credenciales para pagos
        </v-card-title>

        <v-card-text class="mt-8">
          <v-text-field
            name="name"
            label="Paypal"
            id="id"
            outlined
            v-model="credenciales.Paypal"
          ></v-text-field>
          <v-text-field
            name="name"
            label="ATH Movil"
            id="id"
            outlined
            v-model="credenciales.ATHMovil"
          ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="
              $store.dispatch('set_Credenciales', credenciales).then((res) => {
                credencialesPop = false;
              })
            "
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="verDonaciones" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Donaciones </v-card-title>

        <v-card-text class="mt-2">
          <v-row>
            <v-col sm="6">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="Fecha 1"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(date)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6">
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="date2"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date2"
                    label="Fecha 2"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date2" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu2 = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu2.save(date2)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <div style="max-height: 400px; overflow: auto">
            <v-list>
              <v-list-item
                three-line
                v-for="(item, index) in donaciones"
                :key="index"
                ripple
              >
                <v-list-item-content>
                  <v-list-item-title class="flex justify-space-between">
                    <v-tooltip bottom v-if="IsFull(item.Comentario)">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">{{ item.Numref }}</span>
                      </template>
                      <div style="width: 300px">{{ item.Comentario }}</div>
                    </v-tooltip>
                    <div v-else>{{ item.Numref }}</div>
                    <div class="text-body-1">
                      <div>$ {{ item.pago }}</div>
                      <div class="poss_absolute">
                        {{ item.Motivo_Donacion }}
                      </div>
                    </div>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ item.tipo }} - {{ MMddYYYY(item.Fecha) }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <span v-if="IsFull(item.Nombre)"
                      >Nombre: <b>{{ item.Nombre }}</b>
                    </span>
                    <br />
                    <div v-if="IsFull(item.Comentario)">
                      Comentario: {{ item.Comentario }}
                    </div>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <div>Total: $ {{ Total }}</div>
          <v-spacer></v-spacer>
          <v-btn
            v-if="date != '' && date2 != ''"
            color="primary"
            text
            @click="
              $store
                .dispatch('get_Donaciones', { date: date, date2: date2 })
                .then((res) => {
                  donaciones = res;
                  verDonaciones = true;
                })
            "
          >
            Buscar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Paypal -->
  </div>
</template>

<script>
import Header from "../components/header/Header";
import Portfolio from "../components/portfolio/Portfolio";
import ServiceTwo from "../components/service/ServiceTwo";

import FooterTwo from "../components/footer/FooterTwo";

import PortfolioThree from "../components/portfolio/PortfolioThree.vue";
import { IsFull, MMddYYYY } from "../helpers/helpers.js";
import moment from "moment";
import Motivos from "../components/motivos/Motivos.vue";

export default {
  components: {
    Header,
    Portfolio,
    ServiceTwo,

    FooterTwo,

    PortfolioThree,
    Motivos,
  },

  data() {
    return {
      date: "",
      menu: false,
      date2: "",
      menu2: false,
      credencialesPop: false,
      items: [
        {
          thumb: require("../assets/images/blog/bl-big-02.jpg"),
          src: "https://www.youtube.com/watch?v=ZOoVOfieAF8",
        },
      ],
      index: null,
      logo: require("../assets/images/logo/logo.png"),
      dialog: false,
      e1: 1,

      loaded: false,
      paidFor: false,
      product: {
        price: 777.77,
        description: "leg lamp from that one movie",
        img: "./assets/lamp.jpg",
      },
      credenciales: {
        ATHMovil: "",
        Paypal: "",
      },

      verDonaciones: false,
      donaciones: [],

      valid: true,
      toSend: {
        comentario: "",
        Motivo: "",
        Nombre: "",
        Telefono: "",
        Email: "",
        money: 1.0,
        Codigo: "",
      },
      form: [
        {
          model: "Nombre",
          type: "text",
          label: "Nombre (Opcional)",
          class: "text-center text-lg-body-1 mt-3",
          rules: [],
        },
        {
          model: "Email",
          type: "email",
          label: "Email (Opcional)",
          class: "text-center text-lg-body-1 mt-3 ml-3",
          rules: [],
        },
      ],
    };
  },
  computed: {
    Total() {
      let sum = 0;
      this.donaciones.forEach((item) => {
        sum += Number(item.pago);
      });

      return sum.toFixed(2);
    },
  },
  created() {
    this.items[0].src = this.$store.state.iglesia.Video;

    let _this = this;
    window.completePay = function ATHpay(numRef, tipo) {
      _this.Test(numRef, tipo);
    };
  },
  watch: {
    "toSend.Motivo"(newval) {
      this.form = [];
      this.toSend.Codigo = "";

      let NombreForm = {
        model: "Nombre",
        type: "text",
        label: "Nombre (Opcional)",
        class: "text-center text-lg-body-1 mt-3",
        rules: [],
      };
      let EmailForm = {
        model: "Email",
        type: "email",
        label: "Email (Opcional)",
        class: "text-center text-lg-body-1 mt-3 ml-3",
        rules: [],
      };

      if (newval.Requiere_Email == "1") {
        EmailForm = {
          model: "Email",
          type: "email",
          label: "Email (Requerido)",
          class: "text-center text-lg-body-1 mt-3 ml-3",
          rules: [
            (v) => !!v || "Email es requerido",
            (v) => (v && v.length >= 2) || "Debe contener almenos 2 letras",
          ],
        };
      }
      if (newval.Requiere_Nombre == "1") {
        NombreForm = {
          model: "Nombre",
          type: "text",
          label: "Nombre (Requerido)",
          class: "text-center text-lg-body-1 mt-3",
          rules: [
            (v) => !!v || "Nombre es requerido",
            (v) => (v && v.length >= 2) || "Debe contener almenos 2 letras",
          ],
        };
      }
      this.form.push(NombreForm);
      this.form.push(EmailForm);
      if (newval.Requiere_Codigo == "1") {
        let CodigoForm = {
          model: "Codigo",
          type: "text",
          label: "Codigo (Requerido)",
          class: "text-center text-lg-body-1 mt-3 ml-3",
          rules: [(v) => !!v || "Codigo es requerido"],
        };
        this.form.push(CodigoForm);
      }
    },
  },
  methods: {
    formatAsCurrency(value, dec) {
      var regExp = /[a-zA-Z]+/g;

      if (regExp.test(value)) {
        console.log(true);
        this.toSend.money = value.replace(regExp, "");
    
        /* do something if letters are found in your string */
      } else {
        this.toSend.money = value;
        /* do something if letters are not found in your string */
      }
      // return "" + value.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    },
    validate() {
      console.log(this.$refs.form.validate());
      let validate = this.$refs.form.validate();
      if (validate == true) {
        this.OpenPaypal();
        this.OpenAthMovil();
      }
    },
    IsFull,
    MMddYYYY,
    OpenPaypal() {
      const script = document.createElement("script");
      script.src =
        "https://www.paypal.com/sdk/js?client-id=" +
        this.$store.state.iglesia.PaypalKey +
        "&disable-funding=credit";
      script.addEventListener("load", this.setLoaded);
      document.body.appendChild(script);

      this.e1 = 2;
    },

    setLoaded: function () {
      this.loaded = true;
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: this.product.description,
                  amount: {
                    currency_code: "USD",
                    value: this.toSend.money,
                  },
                },
              ],
            });
          },
          onApprove: async (data, actions) => {
            const order = await actions.order.capture();
            this.$store
              .dispatch("post_Donacion", {
                Nombre: this.toSend.Nombre,
                Telefono: this.toSend.Telefono,
                pago: this.toSend.money,
                tipo: "Paypal",
                Numref: order.id,
                Fecha: moment().format("MM/DD/YYYY"),
              })
              .then((response) => {
                this.e1 = 3;
              });
            console.log(order);
          },
          onError: (err) => {
            console.log(err);
          },
        })
        .render(this.$refs.paypal);
    },

    Test(Numref, tipo) {
      this.$store
        .dispatch("post_Donacion", {
          Nombre: this.toSend.Nombre,
          Telefono: this.toSend.Telefono,
          pago: this.toSend.money,
          tipo: tipo,
          Numref: Numref,
          Fecha: moment().format("MM/DD/YYYY"),
          comentario: this.toSend.comentario,
          Email: this.toSend.Email,
          Motivo: this.toSend.Motivo.Id,
          Codigo: this.toSend.Codigo,
        })
        .then((response) => {
          this.e1 = 3;
        });
    },

    OpenAthMovil() {
      const script = document.createElement("script");
      script.src = "https://www.athmovil.com/api/js/v3/athmovilV3.js";
      // script.addEventListener("load", this.LoadAthMovil);
      document.body.appendChild(script);
      this.LoadAthMovil();
    },

    LoadAthMovil() {
      const paymentData = document.createElement("script");
      let key = this.$store.state.iglesia.ATHMovilKey
      let money = this.toSend.money;
      let data = document.createTextNode(`ATHM_Checkout = {
      env: 'production',
      publicToken: '${key}',
      timeout: 500,
      theme: 'btn',
      lang: 'es',
      total: ${money},
      tax: 0.00,
      subtotal: 1.00,
      metadata1: '++ test',
      metadata2: 'metadata2 test',
      items: [
       
      ],
      onCompletedPayment: function (response) {
        window.completePay(response.referenceNumber, 'ATH Movil')
      },
      onCancelledPayment: function (response) {
        // console.log('onCancelledPayment', response)
        // window.canPay = response
      },
      onExpiredPayment: function (response) {
      // console.log('onExpiredPayment', response)
      // window.expirePay = response
      }
    }`);
      // paymentData.async = true;
      paymentData.appendChild(data);
      const script = document.createElement("script");

      script.async = true;

      document.body.appendChild(paymentData);
    },

    parseDate(v) {
      if (v != "") {
        this.date = moment(v).format("MM/DD/YYYY");
      }
    },
  },
};
</script>

<style lang="scss">
@media only screen and (min-width: 1264px) and (max-width: 1903px) {
  .slider-video-bg.slide.slide-style-2 {
    padding-top: 160px;
    padding-bottom: 260px;
    min-height: auto;
  }
}
.stop-btn {
  position: absolute;
  bottom: 0;

  z-index: 10;
}
.object-fit {
  object-fit: cover;
}
.ath {
  width: 100% !important;
  // height: 80% !important;
}
</style>
