var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(_vm.$store.state.admin.Admin == 1)?_c('a',{staticClass:"btn-default btn-large mt--30 ml-2",on:{"click":function($event){_vm.$store.dispatch('get_Motivos_Donacion').then(function (res) {
        _vm.dialog = true;
      })}}},[_c('span',[_vm._v("Motivos")])]):_vm._e(),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Motivos ")]),_c('v-card-text',[_c('div',{staticClass:"flex items-center mt-3"},[_c('v-text-field',{attrs:{"name":"name","label":"Motivo","id":"id"},model:{value:(_vm.Motivo),callback:function ($$v) {_vm.Motivo=$$v},expression:"Motivo"}}),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"success"},on:{"click":function($event){_vm.$store
                .dispatch('post_insertar_Motivos_Donacion', {
                  Motivo: _vm.Motivo,
                })
                .then(function (res) {
                  _vm.Motivo = '';
                  _vm.$store.dispatch('get_Motivos_Donacion').then(function (res) {});
                })}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Titulo")]),_c('th',{staticClass:"text-left"})])]),_c('tbody',_vm._l((_vm.$store.state.motivos_donacion),function(item){return _c('tr',{key:item.name},[_c('td',[_vm._v(_vm._s(item.Motivo_Donacion))]),_c('td',{staticClass:"flex items-center justify-end"},[_c('v-btn',{attrs:{"color":"red","icon":""},on:{"click":function($event){return _vm.onDelete(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-btn',{attrs:{"color":"success","icon":""},on:{"click":function($event){return _vm.onEditar(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)])}),0)]},proxy:true}])})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cerrar ")])],1)],1)],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.borrar),callback:function ($$v) {_vm.borrar=$$v},expression:"borrar"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Borrar ")]),_c('v-card-text',{staticClass:"mt-3"},[_c('p',[_vm._v(" ¿Desea Borrar el motivo "),_c('b',[_vm._v("\" "+_vm._s(_vm.itemSelected.Motivo_Donacion)+" \"")]),_vm._v("? ")])]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){_vm.borrar = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.$store
              .dispatch('post_borrar_Motivos_Donacion', _vm.itemSelected)
              .then(function (res) {
                _vm.$store.dispatch('get_Motivos_Donacion').then(function () {
                  _vm.borrar = false;
                });
              })}}},[_vm._v(" Confirmar ")])],1)],1)],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.editar),callback:function ($$v) {_vm.editar=$$v},expression:"editar"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Editar ")]),_c('v-card-text',{staticClass:"mt-3"},[_c('v-text-field',{attrs:{"name":"name","label":"Motivo","id":"id"},model:{value:(_vm.itemSelected.Motivo_Donacion),callback:function ($$v) {_vm.$set(_vm.itemSelected, "Motivo_Donacion", $$v)},expression:"itemSelected.Motivo_Donacion"}}),_c('div',{staticClass:"flex"},[_c('v-checkbox',{attrs:{"label":"Nombre Obligatorio","true-value":"1","false-value":"0"},model:{value:(_vm.itemSelected.Requiere_Nombre),callback:function ($$v) {_vm.$set(_vm.itemSelected, "Requiere_Nombre", $$v)},expression:"itemSelected.Requiere_Nombre"}}),_c('v-checkbox',{attrs:{"label":"Email Obligatorio","true-value":"1","false-value":"0"},model:{value:(_vm.itemSelected.Requiere_Email),callback:function ($$v) {_vm.$set(_vm.itemSelected, "Requiere_Email", $$v)},expression:"itemSelected.Requiere_Email"}}),_c('v-checkbox',{attrs:{"label":"Codigo Obligatorio","true-value":"1","false-value":"0"},model:{value:(_vm.itemSelected.Requiere_Codigo),callback:function ($$v) {_vm.$set(_vm.itemSelected, "Requiere_Codigo", $$v)},expression:"itemSelected.Requiere_Codigo"}})],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){_vm.editar = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.$store
              .dispatch('post_update_Motivos_Donacion', {
                Id: _vm.itemSelected.Id,
                Motivo: _vm.itemSelected.Motivo_Donacion,
                Requiere_Nombre: _vm.itemSelected.Requiere_Nombre,
                Requiere_Email: _vm.itemSelected.Requiere_Email,
                Requiere_Codigo: _vm.itemSelected.Requiere_Codigo,
              })
              .then(function (res) {
                _vm.$store.dispatch('get_Motivos_Donacion').then(function () {
                  _vm.editar = false;
                });
              })}}},[_vm._v(" Confirmar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }