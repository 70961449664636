<template>
  <span>
    <a
      class="btn-default btn-large mt--30 ml-2"
      v-if="$store.state.admin.Admin == 1"
      @click="
        $store.dispatch('get_Motivos_Donacion').then((res) => {
          dialog = true;
        })
      "
      ><span>Motivos</span></a
    >
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Motivos </v-card-title>

        <v-card-text>
          <div class="flex items-center mt-3">
            <v-text-field
              name="name"
              label="Motivo"
              id="id"
              v-model="Motivo"
            ></v-text-field>
            <v-btn
              class="ml-2"
              color="success"
              @click="
                $store
                  .dispatch('post_insertar_Motivos_Donacion', {
                    Motivo: Motivo,
                  })
                  .then((res) => {
                    Motivo = '';
                    $store.dispatch('get_Motivos_Donacion').then((res) => {});
                  })
              "
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Titulo</th>
                  <th class="text-left"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in $store.state.motivos_donacion"
                  :key="item.name"
                >
                  <td>{{ item.Motivo_Donacion }}</td>
                  <td class="flex items-center justify-end">
                    <v-btn color="red" icon @click="onDelete(item)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn color="success" icon @click="onEditar(item)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="borrar" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Borrar </v-card-title>

        <v-card-text class="mt-3">
          <p>
            ¿Desea Borrar el motivo
            <b>" {{ itemSelected.Motivo_Donacion }} "</b>?
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="borrar = false"> Cancelar </v-btn>
          <v-btn
            color="primary"
            text
            @click="
              $store
                .dispatch('post_borrar_Motivos_Donacion', itemSelected)
                .then((res) => {
                  $store.dispatch('get_Motivos_Donacion').then(() => {
                    borrar = false;
                  });
                })
            "
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editar" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Editar </v-card-title>

        <v-card-text class="mt-3">
          <v-text-field
            name="name"
            label="Motivo"
            id="id"
            v-model="itemSelected.Motivo_Donacion"
          ></v-text-field>
          <div class="flex">
            <v-checkbox
              label="Nombre Obligatorio"
              v-model="itemSelected.Requiere_Nombre"
              true-value="1"
              false-value="0"
            ></v-checkbox>
            <v-checkbox
              label="Email Obligatorio"
              v-model="itemSelected.Requiere_Email"
              true-value="1"
              false-value="0"
            ></v-checkbox>
            <v-checkbox
              label="Codigo Obligatorio"
              v-model="itemSelected.Requiere_Codigo"
              true-value="1"
              false-value="0"
            ></v-checkbox>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="editar = false"> Cancelar </v-btn>
          <v-btn
            color="primary"
            text
            @click="
              $store
                .dispatch('post_update_Motivos_Donacion', {
                  Id: itemSelected.Id,
                  Motivo: itemSelected.Motivo_Donacion,
                  Requiere_Nombre: itemSelected.Requiere_Nombre,
                  Requiere_Email: itemSelected.Requiere_Email,
                  Requiere_Codigo: itemSelected.Requiere_Codigo,
                })
                .then((res) => {
                  $store.dispatch('get_Motivos_Donacion').then(() => {
                    editar = false;
                  });
                })
            "
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      borrar: false,
      editar: false,
      itemSelected: {},
      Motivo: "",
    };
  },
  methods: {
    onDelete(item) {
      this.itemSelected = { ...item };
      this.borrar = true;
    },
    onEditar(item) {
      this.itemSelected = { ...item };
      this.editar = true;
    },
  },
};
</script>

<style>
</style>